import * as stylex from '@stylexjs/stylex';

import { brandColors, fontSize, fontWeight, lineHeight, radius, spacing, systemColors } from '~/styles/tokens.stylex';

export const styles = stylex.create({
  accountantForm: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.xxsmall,
    padding: spacing.normal,
    borderRadius: radius.xxsmall,
    color: systemColors.textDark,
    backgroundColor: brandColors.primary500,
  },

  label: {
    fontSize: fontSize.large,
    fontWeight: fontWeight.bold,
    lineHeight: lineHeight.tight,
  },

  inputWrapper: {
    marginBlockStart: spacing.xxsmall,
    display: 'flex',
    gap: spacing.xxsmall,
  },

  input: {
    flexGrow: 1,
  },
});
