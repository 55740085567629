import * as stylex from '@stylexjs/stylex';

import { fontSize, spacing, systemColors } from '~/styles/tokens.stylex';

export const styles = stylex.create({
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap-reverse',
    justifyContent: 'end',
    alignItems: 'center',
    gap: spacing.small,
  },

  lastUpdatedWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: spacing.xxsmall,
  },

  lastUpdated: {
    fontStyle: 'italic',
    fontSize: fontSize.tiny,
    color: systemColors.textMuted,
  },
});
