import * as stylex from '@stylexjs/stylex';

import { spacing, systemColors } from '~/styles/tokens.stylex';

export const styles = stylex.create({
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.normal,
  },

  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.normal,
  },

  callingCodeOptions: {
    minWidth: 'fit-content',
  },

  callingCodeOption: {
    minWidth: 16, // Ensure the selected option does not shrink
    display: 'flex',
    alignItems: 'center',
    gap: spacing.xxsmall,
  },

  callingCodeTag: {
    color: systemColors.textMuted,
  },

  phoneNumberInputWrapper: {
    display: 'flex',
    gap: spacing.xxsmall,
  },

  phoneNumberInput: {
    width: '100%',
  },

  buttons: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.small,
  },
});
