import * as stylex from '@stylexjs/stylex';

import { fontSize, fontWeight, lineHeight } from '~/styles/tokens.stylex';

import { formColors } from '../tokens.stylex';

export const styles = stylex.create({
  validationError: {
    fontSize: fontSize.small,
    fontWeight: fontWeight.regular,
    lineHeight: lineHeight.tight,
    color: formColors.invalidText,
  },
});
