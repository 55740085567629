import * as stylex from '@stylexjs/stylex';

import { spacing } from '~/styles/tokens.stylex';

export const styles = stylex.create({
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.normal,
  },
});
