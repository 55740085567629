import * as amplitude from '@amplitude/analytics-browser';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import * as stylex from '@stylexjs/stylex';
import { useId } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { match, Pattern } from 'ts-pattern';

import { useCheckEmailAddressInUse, useCreateUncompletedRegistration } from '~/api/auth';
import { CountryFlag } from '~/components';
import {
  Description,
  Form,
  FormField,
  FormInput,
  FormListbox,
  FormValidationError,
  Label,
  LabelDescriptionGroup,
  ListboxOption,
} from '~/components/Form';
import { Redirect } from '~/components/Functional';
import { Alert, AlertDescription, AlertTitle, Button, ButtonGroup, ExternalLink, Text } from '~/components/UI';
import { AmplitudeEventName } from '~/constants/amplitude';
import { countryCallingCodes } from '~/constants/countries';
import { EMAIL } from '~/constants/regex';
import { useIntl } from '~/hooks/useIntl';
import { routes } from '~/providers/RouterProvider/router.routes';
import { parsePhoneNumber } from '~/utils/libphonenumberjs';

import { AuthLayoutContent } from '../AuthLayout/AuthLayoutContent';
import { AuthLayoutTitleGroup } from '../AuthLayout/AuthLayoutTitleGroup';
import { PreviousStepButton } from './PreviousStepButton';
import { useRegistrationContext } from './Registration.context';
import { styles } from './RegistrationAccountInformation.style';
import { RegistrationAddReferralCodeButton } from './RegistrationReferralCode/RegistrationAddReferralCodeButton';
import { RegistrationReferralCode } from './RegistrationReferralCode/RegistrationReferralCode';
import { checkVatInformationStepCompleted } from './utils';

export const RegistrationAccountInformation = () => {
  const createUncompletedRegistrationMutation = useCreateUncompletedRegistration();
  const checkEmailAddressInUseMutation = useCheckEmailAddressInUse();

  const { accountInformation, hadReferralCode, setAccountInformation, vatInformation } = useRegistrationContext();

  const { control, formState, handleSubmit, watch } = useForm({
    defaultValues: accountInformation,
  });
  const formId = useId();
  const watchCallingCode = watch('callingCode');

  const [ref] = useAutoAnimate();
  const navigate = useNavigate();
  const { displayNameOfCountry } = useIntl();
  const { t } = useTranslation(['auth', 'validation']);

  if (!checkVatInformationStepCompleted(vatInformation)) return <Redirect to={routes.register} />;

  const onSubmit = handleSubmit((data) => {
    setAccountInformation(data);

    createUncompletedRegistrationMutation.mutate(
      {
        firstName: vatInformation.founder?.firstName ?? '',
        lastName: vatInformation.founder?.lastName ?? vatInformation.companyName ?? '',
        emailAddress: data.emailAddress,
        phoneNumber: match(data.callingCode)
          .with(
            '32',
            () => parsePhoneNumber(`+${data.callingCode}${data.phoneNumber}`)?.format('E.164') ?? `+${data.callingCode}${data.phoneNumber}`,
          )
          .with('31', () => `+${data.callingCode}${data.phoneNumber}`)
          .with(Pattern.any, () => data.phoneNumber)
          .exhaustive(),
      },
      {
        onSettled: () => {
          amplitude.setUserId(data.emailAddress);
          amplitude.track(AmplitudeEventName.RegistrationStep2Completed);

          navigate(routes.registerBusinessInformation);
        },
      },
    );
  });

  return (
    <AuthLayoutContent>
      <AuthLayoutTitleGroup
        pill={t('auth:registrationPage.common.header.pill')}
        subtitle={t('auth:registrationPage.stepAccountInformation.header.subtitle')}
        title={t('auth:registrationPage.stepAccountInformation.header.title', {
          name: vatInformation.founder?.firstName ?? vatInformation.companyName ?? '',
        })}
      />

      <div ref={ref} {...stylex.props(styles.contentWrapper)}>
        {!vatInformation.hasEligibleNacebelCodes && (
          <Alert variant="warning">
            <AlertTitle>{t('auth:registrationPage.stepAccountInformation.alertNoElibibleNacebelCodes.title')}</AlertTitle>
            <AlertDescription>{t('auth:registrationPage.stepAccountInformation.alertNoElibibleNacebelCodes.description')}</AlertDescription>
          </Alert>
        )}

        <Form id={formId} onSubmit={onSubmit}>
          <div {...stylex.props(styles.form)}>
            <FormField
              control={control}
              name="emailAddress"
              rules={{
                required: true,
                maxLength: 255,
                pattern: EMAIL,
                validate: {
                  unique: async (value) => {
                    try {
                      const { inUse } = await checkEmailAddressInUseMutation.mutateAsync(value.trim());
                      return !inUse;
                    } catch {
                      return false;
                    }
                  },
                },
              }}
            >
              <Label>{t('auth:registrationPage.stepAccountInformation.form.emailField.label')}</Label>
              <FormInput autoFocus={!accountInformation.emailAddress} type="email" />
              <FormValidationError
                maxLength={t('validation:maxLength', {
                  attribute: t('auth:registrationPage.stepAccountInformation.form.emailField.label'),
                  max: 255,
                })}
                pattern={t('validation:email.invalid')}
                required={t('validation:required')}
                validate-unique={
                  checkEmailAddressInUseMutation.isError ? (
                    <Trans
                      components={{ email: <ExternalLink /> }}
                      i18nKey="auth:registrationPage.stepAccountInformation.form.emailField.errors.generic"
                    />
                  ) : (
                    t('auth:registrationPage.stepAccountInformation.form.emailField.errors.exists')
                  )
                }
              />
            </FormField>

            <FormField control={control} name="password" rules={{ required: true, minLength: 8, maxLength: 255 }}>
              <LabelDescriptionGroup>
                <Label>{t('auth:registrationPage.stepAccountInformation.form.passwordField.label')}</Label>
                <Description>{t('auth:registrationPage.stepAccountInformation.form.passwordField.description')}</Description>
              </LabelDescriptionGroup>
              <FormInput type="password" />
              <FormValidationError
                maxLength={t('validation:maxLength', {
                  attribute: t('auth:registrationPage.stepAccountInformation.form.passwordField.label'),
                  max: 255,
                })}
                minLength={t('validation:minLength', {
                  attribute: t('auth:registrationPage.stepAccountInformation.form.passwordField.label'),
                  min: 8,
                })}
                required={t('validation:required')}
              />
            </FormField>

            <FormField
              control={control}
              name="phoneNumber"
              rules={{
                required: true,
                validate: {
                  format: (value, { callingCode }) => callingCode !== '32' || !!parsePhoneNumber(`+${callingCode}${value}`)?.isValid(),
                },
              }}
            >
              <Label>{t('auth:registrationPage.stepAccountInformation.form.phoneNumberField.label')}</Label>
              <div {...stylex.props(styles.phoneNumberInputWrapper)}>
                <FormField control={control} name="callingCode">
                  <FormListbox styles={{ options: styles.callingCodeOptions }}>
                    {(['BE', 'NL', 'XX'] as const).map((countryCode) => (
                      <ListboxOption key={countryCode} value={countryCode === 'XX' ? '' : countryCallingCodes[countryCode]}>
                        {({ selected, selectedOption }) => (
                          <div {...stylex.props(styles.callingCodeOption)}>
                            <CountryFlag countryCode={countryCode} />
                            {!selectedOption && (
                              <Text weight={selected ? 'medium' : 'regular'}>
                                {countryCode === 'XX'
                                  ? t('auth:registrationPage.stepAccountInformation.form.callingCodeField.options.other')
                                  : displayNameOfCountry(countryCode)}
                              </Text>
                            )}
                            {!selectedOption && countryCode !== 'XX' && (
                              <Text styles={styles.callingCodeTag}>+{countryCallingCodes[countryCode]}</Text>
                            )}
                          </div>
                        )}
                      </ListboxOption>
                    ))}
                  </FormListbox>
                </FormField>

                <div {...stylex.props(styles.phoneNumberInput)}>
                  <FormInput prefix={watchCallingCode !== '' ? `+${watchCallingCode}` : undefined} type="tel" />
                </div>
              </div>
              <FormValidationError required={t('validation:required')} validate-format={t('validation:phoneNumber.format')} />
            </FormField>
          </div>
        </Form>

        <RegistrationReferralCode />

        <div {...stylex.props(styles.buttons)}>
          <ButtonGroup wrapReverse>
            <Button form={formId} loading={formState.isValidating} type="submit">
              {t('auth:registrationPage.stepAccountInformation.form.submitButton')}
            </Button>

            {hadReferralCode && <RegistrationAddReferralCodeButton />}
          </ButtonGroup>

          <PreviousStepButton to="vatNumber" />
        </div>
      </div>
    </AuthLayoutContent>
  );
};
