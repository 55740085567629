import * as stylex from '@stylexjs/stylex';

import type { MediaQueryMobileAndBelow } from '~/styles/mediaQueries';

import { spacing, systemColors } from '~/styles/tokens.stylex';

const MobileAndBelow: MediaQueryMobileAndBelow = '@media (width < 768px)';

export const styles = stylex.create({
  article: {
    display: 'grid',
    gridTemplateColumns: {
      default: 'repeat(5, minmax(0, 1fr))',
      [MobileAndBelow]: 'minmax(0, 1fr)',
    },
    gap: spacing.small,
    padding: spacing.normal,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: systemColors.borderLight,
  },

  generalInfo: {
    gridColumn: {
      default: 'span 2',
      [MobileAndBelow]: 'span 1',
    },
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.xxxsmall,
    overflowWrap: 'break-word',
  },

  addressInfo: {
    gridColumn: {
      default: 'span 3',
      [MobileAndBelow]: 'span 1',
    },
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.xxxsmall,
    overflowWrap: 'break-word',
  },

  addressInfoCountry: {
    display: 'flex',
    alignItems: 'center',
    gap: spacing.xxsmall,
  },

  addressInfoCountryName: {
    minWidth: 0, // Allow this flex child to overflow, so that overflow wrap is triggered.
  },
});
