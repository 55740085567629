import * as stylex from '@stylexjs/stylex';

import { fontSize, fontWeight, radius, spacing, systemColors } from '~/styles/tokens.stylex';

export const styles = stylex.create({
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.normal,
  },

  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.normal,
  },

  primaryActivityOption: {
    display: 'flex',
    flexDirection: 'column',
  },

  secondaryActivities: {
    display: 'flex',
    gap: spacing.xxsmall,
    flexWrap: 'wrap',
  },

  secondaryActivityLabel: {
    width: 'fit-content',
    paddingInline: spacing.small,
    paddingBlock: spacing.xsmall,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: systemColors.borderLight,
    borderRadius: radius.xxxsmall,
    fontSize: fontSize.regular,
    fontWeight: fontWeight.regular,
    cursor: 'pointer',
    userSelect: 'none',
  },

  secondaryActivityLabelText: {
    display: 'flex',
    alignItems: 'center',
    gap: spacing.xxsmall,
  },

  buttons: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.small,
  },
});
