import * as stylex from '@stylexjs/stylex';

import { spacing, systemColors } from '~/styles/tokens.stylex';

export const styles = stylex.create({
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.large,
  },

  kboInfoFooter: {
    gridColumn: '1 / -1',
    display: 'flex',
    flexWrap: 'wrap-reverse',
    justifyContent: 'end',
    alignItems: 'center',
    gap: spacing.small,
  },

  lastUpdatedWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: spacing.xxsmall,
    color: systemColors.textMuted,
  },
});
