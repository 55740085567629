import * as stylex from '@stylexjs/stylex';
import { Trans, useTranslation } from 'react-i18next';

import { Field, Input, Label } from '~/components/Form';
import { Redirect } from '~/components/Functional';
import { Alert, AlertDescription, AlertTitle, ExternalLink, Link, Text } from '~/components/UI';
import { routes } from '~/providers/RouterProvider/router.routes';

import { AuthLayoutContent } from '../AuthLayout/AuthLayoutContent';
import { AuthLayoutTitleGroup } from '../AuthLayout/AuthLayoutTitleGroup';
import { AuthLink } from '../AuthLink/AuthLink';
import { useRegistrationContext } from './Registration.context';
import { styles } from './RegistrationCompany.styles';

export const RegistrationCompany = () => {
  const { vatInformation } = useRegistrationContext();

  const { t } = useTranslation(['auth']);

  if (!vatInformation || vatInformation.enterpriseType === 0) return <Redirect to={routes.register} />;

  return (
    <AuthLayoutContent>
      <AuthLayoutTitleGroup
        pill={t('auth:registrationPage.common.header.pill')}
        subtitle={t('auth:registrationPage.stepCompany.header.subtitle')}
        title={t('auth:registrationPage.stepCompany.header.title', { name: vatInformation.companyName })}
      />

      <div {...stylex.props(styles.contentWrapper)}>
        <Field>
          <Label>{t('auth:registrationPage.stepVatNumber.form.vatNumberField.label')}</Label>
          <Input disabled prefix="BE" value={vatInformation.vatNumber.slice(2)} />
          <Text size="small">
            {t('auth:registrationPage.stepCompany.changeVatNumber.message')}{' '}
            <Link to={routes.register}>{t('auth:registrationPage.stepCompany.changeVatNumber.action')}</Link>
          </Text>
        </Field>

        <Alert variant="info">
          <AlertTitle>{t('auth:registrationPage.stepCompany.alert.title')}</AlertTitle>
          <AlertDescription>
            <Trans
              components={{ break: <br />, kiiaLink: <ExternalLink styles={styles.kiiaLink} /> }}
              i18nKey="auth:registrationPage.stepCompany.alert.description"
            />
          </AlertDescription>
        </Alert>
      </div>

      <AuthLink to="login" />
    </AuthLayoutContent>
  );
};
