import * as stylex from '@stylexjs/stylex';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useLinkAccountant } from '~/api/auth';
import { Description, Form, FormField, FormInput, FormValidationError, Label, ValidationError } from '~/components/Form';
import { Button, Text, Title } from '~/components/UI';
import { EMAIL } from '~/constants/regex';
import { darkBackgroundThemes } from '~/styles/themes';

import type { AccountantFormFields } from './RegistrationAccountantForm.types';

import { styles } from './RegistrationAccountantForm.styles';

type Props = {
  userEmailAddress: string;
};

export const RegistrationAccountantForm = ({ userEmailAddress }: Props) => {
  const mutation = useLinkAccountant();

  const { control, handleSubmit } = useForm<AccountantFormFields>({
    defaultValues: { accountantEmailAddress: '' },
  });

  const { t } = useTranslation(['auth', 'validation']);

  const onSubmit = handleSubmit(({ accountantEmailAddress }) => {
    mutation.mutate({ userEmailAddress, accountantEmailAddress });
  });

  return (
    <div {...stylex.props(darkBackgroundThemes, styles.accountantForm)}>
      {mutation.isSuccess ? (
        <>
          <Title as="h5">{t('auth:registrationPage.stepSuccess.linkAccountant.success.title')}</Title>
          <Text size="small">{t('auth:registrationPage.stepSuccess.linkAccountant.success.message')}</Text>
        </>
      ) : (
        <Form onSubmit={onSubmit}>
          <FormField control={control} name="accountantEmailAddress" rules={{ required: true, pattern: EMAIL }}>
            <Label styles={styles.label}>
              {t('auth:registrationPage.stepSuccess.linkAccountant.form.accountantEmailAddressField.label')}
            </Label>

            <Description>{t('auth:registrationPage.stepSuccess.linkAccountant.form.accountantEmailAddressField.description')}</Description>

            <div {...stylex.props(styles.inputWrapper)}>
              <div {...stylex.props(styles.input)}>
                <FormInput type="email" />
              </div>
              <Button icon="Send" loading={mutation.isPending} size="square" type="submit" />
            </div>

            <FormValidationError pattern={t('validation:email.invalid')} required={t('validation:required')} />

            {mutation.isError && <ValidationError>{t('auth:registrationPage.stepSuccess.linkAccountant.errors.generic')}</ValidationError>}
          </FormField>
        </Form>
      )}
    </div>
  );
};
