import { useAutoAnimate } from '@formkit/auto-animate/react';
import * as stylex from '@stylexjs/stylex';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { match, Pattern } from 'ts-pattern';

import { useRequestPasswordReset } from '~/api/auth';
import { BusinessConflict, BusinessConflictError, FetchError, HttpError, TooManyRequestsError } from '~/api/errors';
import { Form, FormField, FormInput, FormValidationError, Label, ValidationError } from '~/components/Form';
import { Alert, AlertDescription, AlertTitle, Button, ExternalLink, LinkButton, Separator } from '~/components/UI';
import { EMAIL } from '~/constants/regex';
import { routes } from '~/providers/RouterProvider/router.routes';

import type { ForgotPasswordFormFields } from './ForgotPassword.types';

import { AuthLayoutContent } from '../AuthLayout/AuthLayoutContent';
import { AuthLayoutTitleGroup } from '../AuthLayout/AuthLayoutTitleGroup';
import { ResendVerificationEmail } from '../ResendVerificationEmail/ResendVerificationEmail';
import { styles } from './ForgotPassword.styles';

export const ForgotPassword = () => {
  const mutation = useRequestPasswordReset();

  const [ref] = useAutoAnimate();
  const { state } = useLocation();
  const { t } = useTranslation(['auth', 'common', 'validation']);

  const { control, handleSubmit } = useForm<ForgotPasswordFormFields>({
    defaultValues: { emailAddress: state?.passwordResetEmailAddress ?? '' },
  });

  // Snapshot of the email address input field at the time of submit,
  // which is used to resend the verification email.
  const [emailAddressSnapshot, setEmailAddressSnapshot] = useState('');

  const onSubmit = handleSubmit((data) => {
    setEmailAddressSnapshot(data.emailAddress);
    mutation.mutate(data.emailAddress);
  });

  return (
    <AuthLayoutContent>
      <AuthLayoutTitleGroup
        pill={t('auth:forgotPasswordPage.header.pill')}
        subtitle={t('auth:forgotPasswordPage.header.subtitle')}
        title={t('auth:forgotPasswordPage.header.title')}
      />

      {state?.isPasswordResetTokenExpired && !mutation.isSuccess && (
        <Alert variant="warning">
          <AlertTitle>{t('auth:forgotPasswordPage.tokenExpiredAlert.title')}</AlertTitle>
          <AlertDescription>{t('auth:forgotPasswordPage.tokenExpiredAlert.description')}</AlertDescription>
        </Alert>
      )}

      <Form onSubmit={onSubmit} ref={ref} {...stylex.props(styles.form)}>
        <FormField control={control} name="emailAddress" rules={{ required: true, pattern: EMAIL }}>
          <Label>{t('auth:loginPage.form.emailField.label')}</Label>
          <FormInput />
          <FormValidationError pattern={t('validation:email.invalid')} required={t('validation:required')} />
        </FormField>

        {mutation.isSuccess && (
          <Alert variant="success">
            <AlertTitle>{t('auth:forgotPasswordPage.successAlert.title')}</AlertTitle>
            <AlertDescription>{t('auth:forgotPasswordPage.successAlert.description')}</AlertDescription>
          </Alert>
        )}

        {mutation.isError && (
          <ValidationError>
            {match(mutation.error)
              .with(
                Pattern.instanceOf(BusinessConflictError),
                (error) => error.errorCode === BusinessConflict.IneligibleUserCannotResetPassword,
                () => (
                  <Alert variant="info">
                    <AlertTitle>{t('auth:forgotPasswordPage.errors.ineligible.title')}</AlertTitle>
                    <AlertDescription>{t('auth:forgotPasswordPage.errors.ineligible.description')}</AlertDescription>
                  </Alert>
                ),
              )
              .with(
                Pattern.instanceOf(BusinessConflictError),
                (error) => error.errorCode === BusinessConflict.UserWithUnverifiedEmailCannotResetPassword,
                () => (
                  <Alert variant="info">
                    <AlertTitle>{t('auth:forgotPasswordPage.errors.unverifiedEmail.title')}</AlertTitle>
                    <AlertDescription>
                      {t('auth:forgotPasswordPage.errors.unverifiedEmail.description')}
                      <Separator spacing="xxsmall" />
                      <ResendVerificationEmail emailAddress={emailAddressSnapshot} linkStyles={styles.resendVerificationEmailLink} />
                    </AlertDescription>
                  </Alert>
                ),
              )
              .with(
                Pattern.instanceOf(BusinessConflictError),
                (error) => error.errorCode === BusinessConflict.RejectedUserCannotResetPassword,
                () => (
                  <Alert variant="error">
                    <AlertTitle>{t('auth:forgotPasswordPage.errors.rejected.title')}</AlertTitle>
                    <AlertDescription>{t('auth:forgotPasswordPage.errors.rejected.description')}</AlertDescription>
                  </Alert>
                ),
              )
              .with(Pattern.instanceOf(TooManyRequestsError), () => t('common:errors.tooManyRequests'))
              .with(Pattern.instanceOf(HttpError), () => (
                <Trans components={{ email: <ExternalLink /> }} i18nKey="common:errors.httpErrorWithLink" />
              ))
              .with(Pattern.instanceOf(FetchError), () => t('common:errors.fetchError'))
              .otherwise(() => t('common:errors.unknown'))}
          </ValidationError>
        )}

        <div {...stylex.props(styles.buttons)}>
          <Button loading={mutation.isPending} type="submit">
            {t('auth:forgotPasswordPage.form.submitButton')}
          </Button>

          <LinkButton to={routes.login} variant="link">
            {t('auth:forgotPasswordPage.loginLink')}
          </LinkButton>
        </div>
      </Form>
    </AuthLayoutContent>
  );
};
