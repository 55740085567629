import * as stylex from '@stylexjs/stylex';

import type { ColorTheme } from '~/api/user/types';

import { formDarkBackgroundTheme } from '~/components/Form/tokens.stylex';

import { brandColors, neutralColors, systemColors } from './tokens.stylex';

const greenPink = stylex.createTheme(brandColors, {
  primary025: 'hsl(160,  27%, 98%)',
  primary050: 'hsl(165,  18%, 96%)',
  primary100: 'hsl(163,  16%, 92%)',
  primary200: 'hsl(164,  17%, 79%)',
  primary300: 'hsl(164,  17%, 66%)',
  primary400: 'hsl(164,  25%, 40%)',
  primary500: 'hsl(164, 100%, 15%)',
  primary600: 'hsl(164, 100%, 13%)',
  primary700: 'hsl(164, 100%, 11%)',
  primary800: 'hsl(164, 100%,  9%)',
  primary900: 'hsl(164, 100%,  7%)',

  secondary050: 'hsl(350, 100%, 99%)',
  secondary100: 'hsl(349, 100%, 98%)',
  secondary200: 'hsl(350, 100%, 94%)',
  secondary300: 'hsl(350, 100%, 91%)',
  secondary400: 'hsl(350, 100%, 84%)',
  secondary500: 'hsl(350, 100%, 77%)',
  secondary600: 'hsl(350,  68%, 70%)',
  secondary700: 'hsl(350,  40%, 58%)',
  secondary800: 'hsl(350,  29%, 46%)',
  secondary900: 'hsl(350,  29%, 38%)',
});

const purpleYellow = stylex.createTheme(brandColors, {
  primary025: '#fcfafc',
  primary050: '#f8f5fa',
  primary100: '#f2ebf5',
  primary200: '#ddcce5',
  primary300: '#c9add5',
  primary400: '#a170b6',
  primary500: '#783296',
  primary600: '#6c2d87',
  primary700: '#5a2671',
  primary800: '#481e5a',
  primary900: '#3b194a',

  secondary050: '#fffdf8',
  secondary100: '#fffcf1',
  secondary200: '#fff6db',
  secondary300: '#fff1c5',
  secondary400: '#ffe79a',
  secondary500: '#ffdc6e',
  secondary600: '#e6c663',
  secondary700: '#bfa553',
  secondary800: '#998442',
  secondary900: '#7d6c36',
});

const bordeauxGray = stylex.createTheme(brandColors, {
  primary025: '#fbf9fa',
  primary050: '#f7f3f5',
  primary100: '#eee7ea',
  primary200: '#d5c2cb',
  primary300: '#bb9dac',
  primary400: '#89546d',
  primary500: '#560a2f',
  primary600: '#4d092a',
  primary700: '#410823',
  primary800: '#34061c',
  primary900: '#2a0517',

  secondary050: '#fefefe',
  secondary100: '#fdfdfe',
  secondary200: '#f9f9fb',
  secondary300: '#f5f5f9',
  secondary400: '#eeeef5',
  secondary500: '#e6e6f0',
  secondary600: '#cfcfd8',
  secondary700: '#adadb4',
  secondary800: '#8a8a90',
  secondary900: '#717176',
});

const blueOrange = stylex.createTheme(brandColors, {
  primary025: '#f9f9fb',
  primary050: '#f5f6fb',
  primary100: '#ebedf7',
  primary200: '#ccd1ea',
  primary300: '#adb5dd',
  primary400: '#707ec4',
  primary500: '#3246aa',
  primary600: '#2d3f99',
  primary700: '#263580',
  primary800: '#1e2a66',
  primary900: '#192253',

  secondary050: '#fffaf7',
  secondary100: '#fff5f0',
  secondary200: '#ffe5d8',
  secondary300: '#ffd5c1',
  secondary400: '#ffb693',
  secondary500: '#ff9664',
  secondary600: '#e6875a',
  secondary700: '#bf714b',
  secondary800: '#995a3c',
  secondary900: '#7d4a31',
});

const redLightBlue = stylex.createTheme(brandColors, {
  primary025: '#fefafa',
  primary050: '#fdf5f4',
  primary100: '#fcebe9',
  primary200: '#f7ccc8',
  primary300: '#f2aea6',
  primary400: '#e87164',
  primary500: '#de3421',
  primary600: '#c82f1e',
  primary700: '#a72719',
  primary800: '#851f14',
  primary900: '#6d1910',

  secondary050: '#fbfdfe',
  secondary100: '#f7fcfe',
  secondary200: '#eaf6fb',
  secondary300: '#ddf1f9',
  secondary400: '#c4e7f5',
  secondary500: '#aadcf0',
  secondary600: '#99c6d8',
  secondary700: '#80a5b4',
  secondary800: '#668490',
  secondary900: '#536c76',
});

export const brandColorThemes = {
  'green-pink': greenPink,
  'purple-yellow': purpleYellow,
  'bordeaux-gray': bordeauxGray,
  'blue-orange': blueOrange,
  'red-lightblue': redLightBlue,
} satisfies Record<ColorTheme, stylex.Theme<typeof brandColors>>;

const systemColorsDarkBackgroundTheme = stylex.createTheme(systemColors, {
  textBlack: neutralColors.white,
  textDark: neutralColors.white,
  textMuted: 'hsla(0, 0%, 100%, 70%)',
  borderLight: neutralColors.white,
  outline: neutralColors.white,
});

export const darkBackgroundThemes = [systemColorsDarkBackgroundTheme, formDarkBackgroundTheme];
