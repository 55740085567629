import * as stylex from '@stylexjs/stylex';

import { spacing } from '~/styles/tokens.stylex';

export const styles = stylex.create({
  referralCodeOption: {
    display: 'flex',
    flexDirection: 'column',
  },

  inputWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: spacing.xxsmall,
  },

  input: {
    flexGrow: 1,
  },
});
