import * as stylex from '@stylexjs/stylex';

import { neutralColors, spacing } from '~/styles/tokens.stylex';

export const styles = stylex.create({
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.normal,
  },

  tooltipLink: {
    color: {
      default: neutralColors.white,
      ':hover': neutralColors.gray100,
    },
  },
});
