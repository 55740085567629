import * as stylex from '@stylexjs/stylex';

import { systemColors } from '~/styles/tokens.stylex';

import { formColors } from '../tokens.stylex';

export const styles = stylex.create({
  icon: {
    flexShrink: 0,
    width: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: systemColors.textMuted,
  },

  invalid: {
    color: formColors.invalidBorder,
  },
});
