import * as stylex from '@stylexjs/stylex';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { match, Pattern } from 'ts-pattern';

import { useFetchInvitations, useFetchReferralCodeInformation } from '~/api/auth';
import { BusinessConflict, BusinessConflictError, FetchError, NotFoundError, TooManyRequestsError } from '~/api/errors';
import {
  Description,
  Form,
  FormField,
  FormInput,
  FormListbox,
  Label,
  LabelDescriptionGroup,
  ListboxOption,
  ValidationError,
} from '~/components/Form';
import { Button, Span } from '~/components/UI';
import { useIntl } from '~/hooks/useIntl';

import type { ReferralCodeFormFields } from './RegistrationReferralCodeForm.types';

import { useRegistrationContext } from '../Registration.context';
import { styles } from './RegistrationReferralCodeForm.styles';

export const RegistrationReferralCodeForm = () => {
  const mutation = useFetchReferralCodeInformation();

  const { data, mutate: fetchInvitations } = useFetchInvitations();
  const invitations = data ?? [];

  const { accountInformation, referralCodeInformation, setReferralCodeInformation, setShowReferralCodeForm } = useRegistrationContext();

  const { control, handleSubmit, setValue } = useForm<ReferralCodeFormFields>({
    defaultValues: {
      referralCode: referralCodeInformation?.code ?? '',
    },
  });

  const { formatDate } = useIntl();
  const { t } = useTranslation(['auth', 'common']);

  useEffect(() => {
    fetchInvitations(accountInformation.emailAddress);
  }, [accountInformation.emailAddress, fetchInvitations]);

  const hasSingleInvitation = invitations.length === 1;
  const hasMultipleInvitations = invitations.length > 1;

  const onSubmit = handleSubmit(({ referralCode }) => {
    if (!referralCode) {
      setReferralCodeInformation(null);
      setShowReferralCodeForm(false);
    }

    const invitation = invitations.find((invitation) => invitation.referralCode.code === referralCode) ?? null;

    if (invitation) {
      setReferralCodeInformation(invitation.referralCode);
      setShowReferralCodeForm(false);
    }

    mutation.mutate(referralCode, {
      onSuccess: (referralCodeInformation) => {
        setReferralCodeInformation(referralCodeInformation);
        setShowReferralCodeForm(false);
      },
    });
  });

  return (
    <Form onSubmit={onSubmit}>
      <FormField control={control} name="referralCode">
        {!hasMultipleInvitations && <Label optional>{t('auth:registrationPage.common.referralCode.form.referralCodeField.label')}</Label>}

        {hasSingleInvitation && referralCodeInformation?.code !== invitations[0].referralCode.code && (
          <Description>
            <Trans
              components={{
                code: (
                  <Button
                    onClick={() => {
                      setValue('referralCode', invitations[0].referralCode.code);
                      onSubmit();
                    }}
                    variant="link"
                  />
                ),
              }}
              i18nKey="auth:registrationPage.common.referralCode.form.referralCodeField.invitationDescription"
              values={{ name: invitations[0].name, code: invitations[0].referralCode.code }}
            />
          </Description>
        )}

        {hasMultipleInvitations && (
          <FormField control={control} name="referralCode">
            <LabelDescriptionGroup>
              <Label optional>{t('auth:registrationPage.common.referralCode.form.referralCodeField.label')}</Label>
              <Description>{t('auth:registrationPage.common.referralCode.form.invitationsField.description')}</Description>
            </LabelDescriptionGroup>

            <FormListbox<string> autoFocus>
              {invitations.map((invitation) => (
                <ListboxOption key={invitation.referralCode.code} value={invitation.referralCode.code}>
                  {({ selectedOption }) => (
                    <div {...stylex.props(styles.referralCodeOption)}>
                      <Span>{invitation.name}</Span>
                      {!selectedOption && (
                        <Span size="small">
                          {t('auth:registrationPage.common.referralCode.form.invitationsField.invitedAt', {
                            date: formatDate(invitation.date),
                          })}
                        </Span>
                      )}
                    </div>
                  )}
                </ListboxOption>
              ))}
            </FormListbox>
          </FormField>
        )}

        <div {...stylex.props(styles.inputWrapper)}>
          <div {...stylex.props(styles.input)}>
            <FormInput autoFocus />
          </div>
          <Button loading={mutation.isPending} size="narrow" type="submit" variant="secondary">
            {t('auth:registrationPage.common.referralCode.form.submitButton')}
          </Button>
        </div>

        {mutation.isError && (
          <ValidationError>
            {match(mutation.error)
              .with(
                Pattern.instanceOf(BusinessConflictError),
                (error) => error.errorCode === BusinessConflict.ReferralCodeExpired,
                () => t('auth:registrationPage.common.referralCode.form.referralCodeField.errors.expired'),
              )
              .with(Pattern.instanceOf(NotFoundError), () =>
                t('auth:registrationPage.common.referralCode.form.referralCodeField.errors.invalid'),
              )
              .with(Pattern.instanceOf(TooManyRequestsError), () => t('common:errors.tooManyRequests'))
              .with(Pattern.instanceOf(FetchError), () => t('common:errors.fetchError'))
              .otherwise(() => t('auth:registrationPage.common.referralCode.form.referralCodeField.errors.generic'))}
          </ValidationError>
        )}
      </FormField>
    </Form>
  );
};
